export default function useCheckGeoAvailability() {
  const stFetch = useRawStFetch()

  async function fetchGeoAvailability() {
    const { error } = await stFetch('/geo/availability/assert', {})
    if (error) {
      console.error(error)
    }
  }

  return { fetchGeoAvailability }
}
